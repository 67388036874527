.btn {
  float: right;
  background: #7db7e3;
  border-radius: 3px;
}

.errorContainer {
  padding: 15rem;
  text-align: center;

  font-weight: 600;
  font-size: 1.25rem;
  color: #1a2560;

  a {
    text-decoration: underline !important;
  }
}

.btnlink {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(123, 182, 225, 0.6);
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: right;
  display: block;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.btnlink:hover {
  color: #1a2560;
}

.fcontainer {
  position: relative;
  float: none;
  display: block;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 97%;
  margin: 0 auto;
  padding-bottom: 130px;
}

.subName {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #1a2560;
  margin-bottom: 40px;
}

.clabel {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: #1a2560;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: left;
  display: flex;
  width: 100%;
}

.cres {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  float: left;
  display: flex;
  width: 100%;
}

.formContainer {
  margin: auto;
  width: 30%;
  padding-bottom: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
}

.formContainer label {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem !important;
  color: #1a2560;
  position: relative;
  float: left;
  display: block;
}

.formContainer input {
  position: relative;
  float: left;
  display: block;
  border: 2px solid rgba(123, 182, 225, 0.6);
  border-radius: 3px;
}

.formupper {
  background: #7bb6e1;
  border-bottom: 6px solid #263489;
  padding: 20px;
  position: relative;
  float: none;
  display: block;
  font-size: 1.25rem;
}

.formupper img {
  position: relative;
}

.formupper span {
  color: white;
  font-weight: bold;
  text-align: right;
  position: absolute;
  padding-right: 20px;
  padding-left: 20px;
  font-family: "Open Sans", sans-serif;
}

.formContainer1 {
  padding: 20px;
  position: relative;
  float: none;
  display: block;
}

.pageContainer {
  background-color: #f6f7fa;
  padding: 80px 0;
}

.container {
  /* margin-top: 40px !important; */
  margin-bottom: 40px;
  /* padding: 30px; */
  /* width: 100%; */
  height: auto;
  background: #fff;
  border: 1px solid rgba(38, 52, 137, 0.16);
}

.fcontainer h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #1a2560;
  background-color: #ffffff;
  margin-top: 80px;
  margin-bottom: 18px;
}

.fileList {
  display: flex;
  justify-content: center;
  width: 100%;
}

.fileList > div {
  min-width: 77%;
  margin: 0 auto;
  justify-content: flex-end;
  margin-left: 0%;
}

.formItem2 {
  border: none;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 68px;
  color: #263489;
  margin-right: 20px;
  display: flex;
  align-items: center;
  box-shadow: none;
  margin-bottom: 1.5rem;
}

.formItem2 img {
  margin-right: 20px;
}

.formItem2 > div {
  padding: 0;
  color: #263489;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  border: 0;
  box-shadow: none;
}

.formItem2 span {
  text-decoration: underline;
  display: contents;
}

.formItemContainer1 {
  max-width: 49%;
  margin: 0 auto;
  margin-left: 36%;
  background-color: #7bb6e114;
  padding: 25px;
  margin-bottom: 80px;
}

.alert {
  color: #7bb6e1;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}

.container > div > div > div {
  max-width: 100%;
  text-align: right !important;
}

.formItem > div,
.formItemActivity > label {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.formItem > div > div,
.formItemActivity > div > div {
  text-align: start !important;
  flex: auto !important;
}

.formItem > div > div:nth-child(1) > label {
  width: 249px;
  float: right;
  white-space: normal;
  justify-content: flex-end;
}

.formItem > div > div > div:nth-child(2),
.formItemActivity > div > div > div:nth-child(2) {
  width: 714px;
}

.formItem label,
.formItemActivity label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px !important;
  line-height: 27px;
  text-align: right;
  color: #1a2560 !important;
  display: flex !important;
  justify-content: flex-end;
  align-items: center !important;
  height: 100% !important;
}

.radio {
  text-align: left !important;
}

.formItem label:after,
.formItemActivity label:after {
  display: none;
}

.input[disabled] {
  background: #f1f1f1 !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
  border-radius: 3px !important;
  height: 60px;
  padding: 12px;
  color: black !important;
  line-height: 1 !important;
}

.input {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
  border-radius: 3px !important;
  height: 60px;
  padding: 12px;
  line-height: 1 !important;
  width: 100%;
}

.inputTextArea {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  /* border-radius: 2px !important; */
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
}

/* .formItem textarea,
.inputTextArea {
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border-radius: 2px !important;
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
  padding: 12px;
  line-height: 1.57;
} */

.checkbox {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1a2560;
}

.buttons {
  /* float: right; */
  max-width: 55%;
  margin: 0 auto;
  margin-left: 41%;
  padding: 25px;
}

.title {
  color: var(--blue);
  font-size: 44px;
  text-align: left;
  font-weight: 700;
  padding: 40px 0px 20px 0px;
  line-height: 1.2;
}

.subTitle {
  color: var(--blue);
  font-size: 22px;
  text-align: left;
  line-height: 1.3;
  padding: 20px 20px 10px 180px;
  white-space: break-spaces;
}

.subTitleText {
  color: var(--blue);
  font-size: 22px;
  text-align: right;
  position: absolute;
  bottom: 70px;
  right: 50px;
}

.subTitleImage {
  text-align: right;
  position: absolute;
  bottom: 0;
}

.hr {
  display: inline-block;
  width: 150px;
  margin-left: 0;
  height: 5px;
  border-width: 0;
  background-color: var(--blue);
  margin: 0px 30px 12px 0px;
}

.image {
  width: 110%;
  height: auto;
}

p {
  white-space: break-spaces;
}

.info {
  max-width: 1186px;
  background-color: rgba(42, 87, 110, 0.2);
  margin: 0 auto;
}

.TitlePosts {
  font-size: 16px;
  color: #2a576e;
  text-align: center !important;
  margin: 0 auto !important;
  background-color: #d4dce0;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .TitlePosts:nth-child(1) {
  border-right: 1px dashed #2a576e;
}
.TitlePosts:nth-child(2) {
  border-right: none !important;
} */

.titleSection {
  font-size: 16px;
  color: #fff;
  text-align: center !important;
  margin: 0 auto;
  padding: 13px 513px 14px 550px;
  background-color: #2a576e;
}

.textBold {
  padding: 78px 78px 0 78px;
  font-size: 29px;
  font-weight: bold;
  color: #2a576e;
}

.text {
  padding: 44px 78px 44px 79px;
  font-size: 16px;
}

.Post {
  padding: 47px 47px;
  max-width: 489px;
  margin: 0 auto;
  /* min-height: 473px; */
}

.ImagePost {
  /* max-height: 324px; */
  max-height: 269px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.Date {
  font-size: 16px;
  color: #696e71;
  margin: 10px auto;
}

.TitlePost {
  font-size: 20px;
  font-weight: 500;
  color: #2a576e;
}

.ExcerptPost {
  font-size: 16px;
  color: #0e0f0f;
  margin-top: 10px;
  margin-right: 6px;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.PostBtn {
  height: 46px;
  flex-grow: 0;
  border-radius: 14px !important;
  background-color: #2a576e;
  margin-top: 20px;
  letter-spacing: 1.82px;
  color: #fff;
  font-size: 11px !important;
  padding: 0 19px !important;
}

.PostBtn span {
  font-family: "Book", Arial, serif !important;
  font-size: 11px;
  letter-spacing: 1.82px;
  color: #fff;
  margin-bottom: 100px !important;
}

.PostBtn:hover,
.PostBtn:focus {
  /* box-shadow: 0 0.1em 0.5em -0.4em white; */
  /* transform: translateY(-0.1em); */
  border-bottom: 1px solid transparent;
  /* transform: translateX(0.2em); */
  /* border-color: #fff !important; */

  -webkit-transform: scale(0.95) !important;
  -moz-transform: scale(0.95) !important;
  -ms-transform: scale(0.95) !important;
  -o-transform: scale(0.95) !important;
  transform: scale(0.95) !important;
  transition: 0.4s;
}

.pagination {
  margin-top: 55px;
  display: flex !important;
  justify-content: center !important;
}

.pagination li,
.ant-pagination-item-active:active {
  min-height: 47px;
  min-width: 47px !important;
  height: 36px;
  /* background: transparent !important; */
  display: grid !important;
  align-items: center;
  border-color: #bcbcbc !important;
}

.pagination li:first-child,
.pagination li:last-child {
  display: none !important;
}

.pagination a {
  color: #bcbcbc !important;
}

.pagination .ant-pagination-item-active {
  background-color: #2a576e !important;
}

li > div {
  max-width: 100%;
}

.dashed {
  border-right: 1px dashed #2a576e;
}

.container {
  max-width: 1320px;
  margin: 0 auto;
}

.imageWelcome {
  height: 596px;
  background: linear-gradient(
      105.32deg,
      rgba(38, 52, 137, 0.45) 36.89%,
      rgba(38, 52, 137, 0) 80.06%
    ),
    url("../../../public/img/Image_WelcomePage.png");
  z-index: 3;
}

.imageWelcomeTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 68px;
  color: #ffffff;
  max-width: 929px;
  padding-top: 200px;
  padding-left: 80px;
  margin-bottom: 40px;
}

.imageWelcomeDesc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;
  max-width: 929px;
  padding-left: 80px;
}

.benefitsContainer {
  padding: 80px 0;
}

.requests {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 120px;
}

.request {
  border: 1px solid rgba(123, 182, 225, 0.6);
  border-radius: 30px;
  padding: 42px 36px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  flex-direction: column;
  max-width: 440px;
}

.requestTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #1a2560;
  max-width: 319px;
  margin: 0 auto 32px auto;
}

.requestDesc {
  margin-bottom: 42px;
  max-width: 369px;
  margin: 0 auto 42px auto;
  text-align: center;
}

.requestButton {
  background-color: #263489;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}

.requestButton img {
  height: 20px;
  width: 16px;
}

.benefits > span {
  margin-bottom: 40px !important;
}

.benefitsTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  /* identical to box height */

  color: #1a2560;
  margin-bottom: 40px;
}

.benefits {
  max-width: 1160px;
  margin: 0 auto;
  padding: 56px 25px;
}

.benefitsExtra {
  max-width: 1050px;
  margin: 0 auto;
  padding: 0;
}

.benefitsWrapper {
  background: rgba(38, 52, 137, 0.02);
  border-radius: 8px;
}

.benefit {
  border-top: 1px solid rgba(38, 52, 137, 0.06);
  padding: 36px 0 36px 0;
}

.Title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #1a2560;
  margin-bottom: 24px;
}

.Date {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #7bb6e1;
  margin-bottom: 16px;
}

.Desc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #1a2560;
}

.serviceIcon {
  float: right;
  margin-left: 2rem;
}

.benefitCol {
  display: flex;
  align-items: center;
  height: 100%;
}

.reset {
  background: transparent;
  border: none !important;
  border-color: transparent;
  border-radius: 67.5px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: rgba(26, 37, 96, 0.6);
  padding: 17px 63px;
  height: 100%;
  box-shadow: none !important;
}

.reset:focus-visible .reset:focus {
  outline: 0px solid transparent !important;
}

.submit {
  background: #263489;
  border-radius: 67.5px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  padding: 17px 63px;
  height: 100%;
}

.submit:hover {
  background-color: #78b7e3 !important;
}

.overlay_header {
  color: #eee;
  text-align: right;
  padding: 0 1rem 1rem 1rem;
}

.cross {
  font-size: 2rem !important;
  background-color: transparent;
  color: #122b52;
  border: none;
  cursor: pointer;
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(123, 182, 225, 0.08);
  border-radius: 300px;
  height: 242px;
  width: 242px;
  margin: 0 auto 40px auto;
}

.message {
  max-width: 478px;
  margin: 0 auto;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  /* or 180% */

  text-align: center;

  color: #1a2560;
}

.select > div {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(38, 52, 137, 0.3) !important;
}

.RadioContainer {
  width: 100%;
}

.RadioContainer label {
  justify-content: space-between !important;
  flex-direction: row-reverse;
}

.formItem h3,
.formItemActivity h3 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #1a2560;
  margin-bottom: 63px;
}

.formItem h4,
.formItemActivity h4 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #1a2560;
  margin-bottom: -15px;
}

/* .fileListWrap {
  max-width: 418px;
} */

.requiredFile {
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #263489;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.checkboxContainer {
  max-width: 54%;
  margin: 0 auto;
  margin-left: 36.5%;
}

.checkboxContainer span:nth-child(1) {
  margin-right: 5%;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: 0 100px;
  /* max-width: 422px; */
  padding-top: 20px;
}

.radioGroup label {
  display: flex;
  flex-direction: row-reverse;

  justify-content: space-between;

  color: #1a2560;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radioGroup label > span:nth-child(1) {
  border-radius: 27px;
  border: 1px solid rgba(38, 52, 137, 0.3);
  background: #fff;
  padding: 4px !important;
}

.radioGroup label > span > span:nth-child(2) {
  width: 12px !important;
  height: 12px !important;
  border: 0 !important;
}

.radioGroup ::after {
  display: none;
}

.warning {
  color: #ff4d4f;
  margin-top: 1rem;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .formItem > div,
  .formItemActivity > div {
    gap: 45px;
    max-width: 89%;
    margin: 0 auto;
  }

  .fileList > div {
    margin-right: 14% !important;
  }

  .checkboxContainer {
    margin-left: 33.5%;
  }

  .radioGroup {
    gap: 1px;
    margin: 0 auto;
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .fileList > div {
    margin-right: 20% !important;
  }

  .checkboxContainer {
    margin-left: 28.5%;
  }
}

@media only screen and (max-width: 767px) {
  .fileList > div {
    margin-left: 0% !important;
    width: none;
    max-width: none;
    margin: 0 !important;
    min-width: 0%;
  }

  .checkboxContainer {
    margin-left: 6%;
    width: 100%;
    max-width: 79% !important;
  }

  .formItem label,
  .formItemActivity label {
    justify-content: flex-start;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 30px;
    padding: 0px;
  }

  .subTitle {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0px;
  }

  .hr {
    width: 0px;
    margin: 0px;
  }

  .subTitleText {
    margin-top: 20px;
    bottom: -20px;
    right: 210px;
  }

  .subTitleImage {
    bottom: -20px;
  }

  .TitlePosts,
  .PostCol {
    border-right-color: transparent !important;
  }

  .dashed {
    border-right: none !important;
  }

  .serviceIcon {
    margin-top: 20px;
    float: none;
  }

  .fcontainer {
    /* max-width: 90%; */
    margin: 0 auto;
    /* padding-bottom: 230px; */
  }

  .fcontainer form {
    max-width: 100% !important;
  }

  .formItem > div > div > div,
  .formItemActivity > div > div > div {
    width: 100%;
  }

  .select {
    width: 100% !important;
  }

  .buttons {
    max-width: 100%;
    margin: 0 auto;
    margin-left: 0;
    padding: 25px;
  }

  .buttons div {
    display: flex;
    justify-content: center;
  }

  .checkboxContainer {
    max-width: 100%;
    margin: 0 auto;
    margin-left: 5%;
  }

  .formItemContainer1 {
    max-width: 100%;
    margin: 0 auto;
    margin-left: 5%;
    background-color: #7bb6e114;
    padding: 25px;
    margin-bottom: 80px;
  }

  .formItem2 {
    white-space: normal;
  }

  .formItem2 span {
    font-size: 12px;
  }

  .formItem > div,
  .formItemActivity > div {
    gap: 1vw;
    max-width: 89%;
    margin: 0 auto;
  }

  .formItem label,
  .formItemActivity label {
    justify-content: flex-start;
  }

  .formItem > div > div:nth-child(1) > label,
  .formItemActivity > div > div:nth-child(1) > label {
    width: 600px;
    float: right;
    white-space: normal;
    text-align: left;
  }

  .formItem > div > div:nth-child(1) > label {
    width: 249px;
    float: left;
    white-space: normal;
    justify-content: flex-start;
  }

  .fcontainer {
    padding-bottom: 30px;
  }

  .fcontainer h2 {
    font-size: 18px;
    margin-top: 0;
  }

  .formItem h3,
  .formItemActivity h3 {
    font-size: 16px;
  }

  .pageContainer {
    padding: 0;
  }

  .subName {
    font-size: 14px;
  }

  .formItem > div > div:nth-child(1) > label,
  .formItemActivity > div > div:nth-child(1) > label {
    font-size: 14px !important;
  }

  .checkbox {
    font-size: 12px !important;
  }

  .alert {
    font-size: 12px;
  }

  .reset,
  .submit {
    font-size: 14px;
    padding: 12px 16px;
  }

  .container {
    margin-bottom: 0;
  }

  .radio span {
    font-size: 14px !important;
  }
}
